document.addEventListener("DOMContentLoaded", function(){

    $('[data-toggle="tooltip"]').tooltip();

    (function() { //run date time picker
        var datePickerElement = [
            "member-date-of-birth",
            "reference-date-of-bith",
            "deviceDeadDate",
            "cost-start",
            "cost-end",
            "card-issue",
            "card-expire"
        ];

        datePickerElement.forEach(function(elementid) {
            var elements = $("#" + elementid);
            var config_options = {
                autoclose: true
            }
            elements ? elements.datepicker(config_options) : "";
        });
    })();

}, true);

